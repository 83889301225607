<template>
  <div
    class="item-question pl-20 pr-20 pt-10 pb-10 flex ai-fs jc-sb ggap-10 pos-r"
    :class="{ active: hasAlias, 'fd-c': isMobile }"
  >
    <!-- Delete -->
    <div v-if="isDelete" class="item-question__delete pos-a z1 flex ai-c jc-c ggap-10">
      <div v-if="isLoad" class="flex ai-c ggap-5">
        <BaseLoad class="rel sm" />
        <h3 class="title t-white">Идет удаление записи!</h3>
      </div>
      <template v-else>
        <h3 class="title t-white">Вы действительно хотите удалить вопрос?</h3>
        <button class="btn primary sm" @click="deleteAction">Удалить</button>
        <button class="btn cancel sm" @click="isDelete = false">Отмена</button>
      </template>
    </div>

    <!-- Message -->
    <div class="item-question__grid grid ggap-10 ai-fs">
      <span class="item-question__grid-index flex ai-c">
        <div class="flex ai-c jc-c user-select-none">{{ index }}</div>
      </span>
      <span class="item-question__grid-message grid ai-c h-100">
        {{ item.name }}
      </span>
      <div v-if="item.author" class="flex ai-c w-500 h-100 ml-35">
        <div class="marker primary">Автор: {{ item.author?.surname }} {{ item.author?.name }}</div>
      </div>
    </div>

    <!-- Actions -->
    <div class="flex ai-c ggap-5">
      <button
        class="btn primary-border sm pl-10 pr-10"
        @click="getComments(item, !showComments)"
        :class="{ activeQuestion: showComments }"
      >
        <BaseIcon v-if="!isLoadComments" :class="{ white: showComments }" class="ic-16" icon="comment-circle" />
        <BaseLoad v-if="isLoadComments" class="rel sm" />
        <span>{{ item.parent_comments || 0 }}</span>
      </button>
      <button
        class="btn primary-border sm pl-10 pr-10"
        :class="{ activeQuestion: item.liked }"
        @click="toggleLike(true)"
      >
        <BaseLoad v-if="isLoadLike" class="rel sm" />
        <template v-else>
          <BaseIcon class="ic-16" :class="{ white: item.liked }" icon="like" />
          {{ item.likes }}
        </template>
      </button>
      <button
        class="btn primary-border sm pl-10 pr-10"
        :class="{ activeQuestion: item.disliked }"
        @click="toggleLike(false)"
      >
        <BaseLoad v-if="isLoadDislike" class="rel sm" />
        <template v-else>
          <BaseIcon class="ic-16 reverse" :class="{ white: item.disliked }" icon="like" />
          {{ item.dislikes || 0 }}
        </template>
      </button>
      <BaseDropDown btn-class="btn sm grey pl-10 pr-10 fw-b" v-if="isAdmin">
        <template #btn> ... </template>
        <template #body>
          <div>
            <button class="btn sm pl-10 pr-10" @click="isDelete = !isDelete">
              <BaseIcon class="ic-16 red" icon="delete" />
              Удалить вопрос
            </button>
          </div>
        </template>
      </BaseDropDown>
    </div>
    <!-- Actions -->
  </div>
  <!-- Comments -->
  <div class="all-comments" v-if="showComments">
    <div class="item-post__foot p-10 b-t">
      <BaseLoad v-if="isLoadComments" class="rel sm" v-tippy="'Идет загрузка коментариев'" />
      <div class="p-5 pl-40 all-comments">
        <!-- all comments -->
        <EventComments
          v-if="item?.commentaries && item?.commentaries.length && item?.commentaries[0]"
          :items="item?.commentaries"
          :questionId="item?.id"
          @mainFormClose="mainFormClose"
          @updateQuestion="getComments(item, true)"
        />
        <!-- all comments -->
        <button
          v-if="showAllComments"
          class="btn sm grey w-100 jc-c mb-20"
          @click.prevent="getComments(item, true, 'all')"
        >
          <BaseLoad v-if="isLoadComments" class="rel sm" v-tippy="'Идет загрузка коментариев'" />
          <span v-if="!isLoadComments">показать все комментарии</span>
        </button>
        <button
          v-if="item.commentaries.length > 5"
          class="btn sm grey w-100 jc-c mb-20"
          @click="getComments(item, !showComments)"
        >
          <span>скрыть комментарии вопроса</span>
        </button>
        <EventCommentsForm
          :questionId="item?.id"
          @updateQuestion="getComments(item, true)"
          :class="{ 'mt-10': item?.commentaries && item?.commentaries.length, sticky: isCommentsFull }"
        />
      </div>
    </div>
  </div>
  <!-- Comments -->
</template>

<script setup>
import { useStore } from 'vuex'
import { defineEmits, defineProps, toRefs, computed, ref, watch, nextTick } from 'vue'
import { BaseLoad, BaseIcon, BaseDropDown } from '@/components'
import { EventComments, EventCommentsForm } from '@/components'
import { events } from '@/api'

// Emits
const emits = defineEmits('updateItem')

// Props
const props = defineProps({
  index: {
    type: Number,
    default: 1
  },
  item: {
    type: Object,
    default: () => ({})
  },
  aliasList: {
    type: Array,
    default: () => []
  },
  status: {
    type: String,
    default: 'active'
  }
})

// Data
const { index, item, aliasList, status } = toRefs(props)
const store = useStore()
const likesCount = ref(0)
const isDelete = ref(false)
const isLoad = ref(false)
const isLoadLike = ref(false)
const isLoadDislike = ref(false)
const isLoadComments = ref(false)
const showComments = ref(false)
const meta = ref({})

// Computed
const showAllComments = computed(() => meta.value > item.value?.commentaries?.length)
const isMobile = computed(() => store.getters.isMobile)
const profile = computed(() => store.getters.profile)
const isAdmin = computed(() => profile.value?.negotiation_role === 'Admin')
const hasAlias = computed(
  () =>
    aliasList.value.findIndex((el) => el.name.toLocaleLowerCase().includes(item.value.name.toLocaleLowerCase())) !== -1
)

// Created
likesCount.value = item.value.likes

watch(item, () => {
  isLoadLike.value = false
  isLoadDislike.value = false
})

// Methods
async function toggleLike(flag) {
  if (flag) {
    item.value.liked = !item.value.liked
    isLoadLike.value = true
  } else {
    item.value.disliked = !item.value.disliked
    isLoadDislike.value = true
  }

  if (status.value !== 'active') return

  try {
    if (flag) {
      await events.likeQuestion(item.value.id)
      if (item.value?.disliked) {
        await events.dislikeQuestion(item.value.id)
        item.value.disliked = false
        item.value.dislikes -= 1
      }
    } else {
      await events.dislikeQuestion(item.value.id)
      if (item.value?.liked) {
        await events.likeQuestion(item.value.id)
        item.value.liked = false
        item.value.likes -= 1
      }
    }
    emits('updateItem')
  } catch (error) {
    console.log(error)
  }
}

async function deleteAction() {
  isLoad.value = true
  try {
    await events.deleteQuestion(item.value.id)
    emits('updateItem')
  } catch (error) {
    console.log(error)
  } finally {
    isLoad.value = false
    isDelete.value = false
  }
}

async function getComments(param, flag = false, type = '') {
  try {
    await nextTick()
    if (flag) {
      isLoadComments.value = true
      const { data } = await events.getComments(param?.id, type)
      meta.value = data?.meta?.total
      if (type === 'all') {
        item.value.comments = data?.data?.length
      } else {
        item.value.comments = data?.meta?.total
      }
      item.value.commentaries = data?.data
      console.log(item.value)
      console.log(data)
      isLoadComments.value = false
    }
    showComments.value = flag
  } catch (err) {
    console.log(err)
  }
}
</script>

<style lang="scss" scoped>
.activeQuestion {
  background-color: var(--primary);
  color: white;
}
.item-question {
  border-top: var(--b1);

  &:first-child {
    border-top: 0;
    border-radius: var(--br) var(--br) 0 0;
  }
  &:last-child {
    border-radius: 0 0 var(--br) var(--br);
  }
  &.single {
    border: 0;
    border-radius: var(--br);
  }

  &__grid {
    grid-template-columns: 24px 1fr;
    min-height: 30px;

    &-index {
      height: 30px;

      & > div {
        background-color: var(--grey-el);
        color: var(--text);
        border-radius: 100px;
        width: 24px;
        height: 24px;
      }
    }

    &-message {
      line-height: 20px;
    }
  }

  &.active &__grid-index > div {
    background-color: var(--secondary);
    color: #fff;
  }

  &__delete {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: var(--rgba-red-08);
    backdrop-filter: blur(5px);
  }
}
</style>
